import type {ViaEnum} from '@core/types/graphql';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrlForCoins from '@core/payment/common/utils/generatePayUrlForCoins';

/**
 * Create handler for go to PP for coins
 */
const goToCoinsPP = (via: ViaEnum, returnPath: string | null = null): void => {
  const history = getHistory();
  history.push(generatePayUrlForCoins({via, returnPath}));
};

export default goToCoinsPP;
