import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {ViaEnum} from '@core/types/graphql';
import logger from '@core/logger';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import useOneClick from '@core/payment/forms/card/utils/useOneClick';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import useEventCallback from '@core/utils/react/useEventCallback';
import usePaymentPackageDescriptionRule from '@core/payment/widgets/packageDescription/utils/usePaymentPackageDescriptionRule';
import PopupContext from '@core/popup/containers/PopupContext';

import {Loader} from '@phoenix/ui';
import PaymentFooter from '@phoenix/payment/widgets/footer/containers/PaymentFooter';
import PaymentCardOneClickInfo from '@phoenix/payment/forms/card/components/PaymentCardOneClickInfo';
import trackRmPopup from '@phoenix/tracking/rmPopup/trackRmPopup';

import RemarketingToggleSubscription from './RemarketingToggleSubscription';
import RemarketingPackageDescription from './RemarketingPackageDescription';
import {CHANGE_CLICK} from '../constants/trackingName';
import RemarketingOfferPaymentDetailsLayout from '../components/RemarketingOfferPaymentDetailsLayout';

const RemarketingOfferPaymentDetails = ({
  forFeatures,
  packageId,
  hidden,
  onCheckboxChange,
  returnPath,
  trackingConceptName,
  withOneClickInfo,
  paymentPackage,
  isStrictLegalMode,
  paymentButtonTitle,
}) => {
  const history = useHistory();
  const isSmallTablet = useSmallTabletBreakpoint();

  const {loading, isOneClickAllowed, oneClickCardNumber} = useOneClick({
    skip: !withOneClickInfo,
  });

  const {
    loading: packageDescriptionLoading,
    isWithBillingPolicy,
    packageDescriptionRule,
  } = usePaymentPackageDescriptionRule();

  const {
    setPopupProps,
    options: {showCloseButton}, // Used in useEffect dependency for correct update in case of pop ups queue
  } = useContext(PopupContext);

  useEffect(() => {
    !loading &&
      !packageDescriptionLoading &&
      setPopupProps({showCloseButton: true});
  }, [loading, packageDescriptionLoading, showCloseButton, setPopupProps]);

  const handleClick = useEventCallback(() => {
    trackRmPopup({
      popupId: trackingConceptName,
      action: CHANGE_CLICK,
    });

    const paymentUrl = generatePayUrl({
      stage: forFeatures
        ? PAYMENT_ACTIONS.FEATURES
        : PAYMENT_ACTIONS.MEMBERSHIP,
      urlParams: {
        via: ViaEnum.remarketing_popup,
        returnPath,
        skipStepOnBack: true,
        stockId: packageId,
      },
    });

    history.push(paymentUrl);
  });

  if (loading || packageDescriptionLoading) {
    return (
      <RemarketingOfferPaymentDetailsLayout hidden={false}>
        <Loader />
      </RemarketingOfferPaymentDetailsLayout>
    );
  }

  if (!isOneClickAllowed && withOneClickInfo) {
    logger.sendWarning(
      '[RemarketingOfferPaymentDetails] No oneClick found for card method',
    );
  }

  return (
    <RemarketingOfferPaymentDetailsLayout hidden={hidden}>
      {isOneClickAllowed && withOneClickInfo && (
        <PaymentCardOneClickInfo
          showChargeInfo={false}
          inline={!isSmallTablet}
          cardNumber={oneClickCardNumber}
          onClick={handleClick}
          oneClickUsed
        />
      )}
      <PaymentFooter
        withDnsmpi={!isStrictLegalMode}
        onlyProcessingFeeText
        paymentButtonTitle={paymentButtonTitle}
        packageDescription={
          Boolean(packageDescriptionRule) && (
            <RemarketingPackageDescription
              translateMessages={packageDescriptionRule.translateMessages}
              paymentButtonTitle={paymentButtonTitle}
              onCancelChange={onCheckboxChange}
              isStrictLegalMode={isStrictLegalMode}
            />
          )
        }
        subscriptionInfo={
          isWithBillingPolicy ? null : (
            <RemarketingToggleSubscription
              paymentPackage={paymentPackage}
              onCheckboxChange={onCheckboxChange}
            />
          )
        }
      />
    </RemarketingOfferPaymentDetailsLayout>
  );
};

RemarketingOfferPaymentDetails.propTypes /* remove-proptypes */ = {
  packageId: PropTypes.string.isRequired,
  trackingConceptName: PropTypes.string.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  returnPath: PropTypes.string.isRequired,
  forFeatures: PropTypes.bool.isRequired,
  withOneClickInfo: PropTypes.bool.isRequired,
  paymentPackage: PropTypes.objectOf(PropTypes.any).isRequired,
  isStrictLegalMode: PropTypes.bool.isRequired,
  paymentButtonTitle: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
};

RemarketingOfferPaymentDetails.defaults = {
  forFeatures: false,
  withOneClickInfo: true,
};

export default withStaticPaymentParamsProvider(
  RemarketingOfferPaymentDetails,
  ({forFeatures}) => ({
    action: forFeatures ? PAYMENT_ACTIONS.FEATURES : PAYMENT_ACTIONS.MEMBERSHIP,
    via: ViaEnum.remarketing_show_popup,
    source: PAYMENT_SOURCES.POPUP,
  }),
);
