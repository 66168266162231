import defer from 'lodash/defer';
import {Subject} from 'rxjs';

import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import clearSearchBannersCache from '@core/graphql/utils/clearSearchBannersCache';
import {writeToSessionStorage} from '@core/utils/storage';

import {
  RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED,
  RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED,
} from '@phoenix/messenger/constants/storageKeys';
import clearReelsCache from '@phoenix/reels/utils/clearReelsCache';
import isEnabledPmaRequestPhoto from '@phoenix/user/photo/utils/isEnabledPmaRequestPhoto';

import DETAILS_AFTER_PAYMENT_QUERY from '../graphql/queries/detailsAfterPayment.gql';
import updateMicroFeaturesData from './updateMicroFeaturesData';
import invalidateCacheIsProfileVideoUploadDisabled from './invalidateCacheIsProfileVideoUploadDisabled';

/**
 * Is updating data after payment Observable.
 * Use @apollo/client features instead.
 * @deprecated
 */
export const isUpdating$ = new Subject();

let updating = false;

/**
 * Is updating data after payment.
 * @deprecated
 * @return {boolean}
 */
export const isUpdating = () => updating;

/**
 * @param {boolean} value
 */
const setUpdating = (value) => {
  updating = value;
  isUpdating$.next(value);
};

type UpdateCachesAfterPayment = {
  triggerUpdate?: boolean;
  invalidateArgs?: string[];
  broadcast?: boolean;
};

/**
 * Updates payment-dependent data.
 */
const updateCachesAfterPayment = ({
  triggerUpdate = true,
  invalidateArgs,
  broadcast = true,
}: UpdateCachesAfterPayment = {}) => {
  const client = getClientInstance();

  if (triggerUpdate) {
    setUpdating(true);
  }

  const result = client
    .query({
      query: DETAILS_AFTER_PAYMENT_QUERY,
      fetchPolicy: 'network-only',
    })
    .then(() => {
      setUpdating(false);
    });

  /**
   * Remove all possible users from cache due to trusted functionality.
   * The main problem is, that we can't send real 'trustedStatus' of users
   * from server and take this status unchanged since evil users can
   * identify trusted users and push them with fraud messages.
   *
   * This, trusted flag, is always 'false' if you are not trusted. When you
   * became trusted (via payment) we should update this flag.
   */
  invalidateCacheByTypename(client, 'UserData', {
    fieldName: 'viewPermissions',
    broadcast,
  });
  invalidateCacheByTypename(client, 'UserData', {
    fieldName: 'messagePermissions',
    broadcast,
  });

  // Messenger
  invalidateCacheByTypename(client, 'Messenger', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'Message', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'Recipient', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'MessengerData', {
    fieldName: 'recipients',
    broadcast,
  });
  invalidateCacheByTypename(client, 'GlobalFreeMessages', {
    fieldName: 'freeMessagesTimeout',
    broadcast,
  });

  updateMicroFeaturesData({broadcast});

  writeToSessionStorage(RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED, null, true);
  writeToSessionStorage(
    RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED,
    null,
    true,
  );

  // not PP pages
  invalidateCacheByTypename(client, 'Search', {
    fieldName: 'settings',
    broadcast,
  });
  invalidateCacheByTypename(client, 'Search', {
    fieldName: 'features',
    broadcast,
  });
  invalidateCacheByTypename(client, 'Search', {
    fieldName: 'users',
    broadcast,
  });
  invalidateCacheByTypename(client, 'searchWithMap', {
    fieldName: 'hasFreeTrialAvailable',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ROOT_QUERY', {
    fieldName: 'billingHistory',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ROOT_QUERY', {
    fieldName: 'remarketing',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ROOT_QUERY', {
    fieldName: 'searchWithMap',
    broadcast,
  });
  invalidateCacheByTypename(client, 'Banners', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'marketingProductConfigurationName',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ROOT_QUERY', {
    fieldName: 'gifts',
    broadcast,
  });
  invalidateCacheByTypename(client, 'ROOT_QUERY', {
    fieldName: 'support',
    broadcast,
  });

  clearSearchBannersCache(client, broadcast);

  defer(() => {
    invalidateCacheByTypename(client, 'Payment', {
      fieldName: 'banner',
      broadcast,
    });
    invalidateCacheByTypename(client, 'Payment', {
      fieldName: 'accountStatus',
      broadcast,
    });
    invalidateCacheByTypename(client, 'Payment', {
      fieldName: 'discountData',
      broadcast,
    });
    invalidateCacheByTypename(client, 'Payment', {
      fieldName: 'isUserBoughtPackage',
      broadcast,
    });
  });

  invalidateCacheByTypename(client, 'AlertBanner', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'AccountStatus', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'Credits', {
    broadcast,
  });
  invalidateCacheByTypename(client, 'MotivationBanner', {
    broadcast,
  });

  invalidateCacheByTypename(client, 'RemoveAccount', {
    fieldName: 'isCancelBillingPageBeforeRemove',
    broadcast,
  });

  // Clear virtual assistant cache
  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'bioGeneration',
    broadcast,
  });
  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'bioGenerationV2',
    broadcast,
  });
  invalidateCacheByTypename(client, 'UserFeatures', {
    fieldName: 'messageAssistant',
    broadcast,
  });

  invalidateCacheIsProfileVideoUploadDisabled(client, broadcast);

  invalidateCacheByTypename(client, 'WorldWideSearch', {
    broadcast,
  });

  // Clear reels data
  clearReelsCache(client, broadcast);

  // Clear pma photo request cache
  if (isEnabledPmaRequestPhoto()) {
    invalidateCacheByTypename(client, 'UserFeatures', {
      fieldName: 'pmaRequestPhoto',
      broadcast,
    });
  }

  if (invalidateArgs) {
    const [typeName, fieldName] = invalidateArgs;
    invalidateCacheByTypename(client, typeName, {fieldName, broadcast});
  }

  return result;
};

export default updateCachesAfterPayment;
