import {ViaEnum} from '@core/types/graphql';
import {getClientInstance} from '@core/graphql/client';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import PAYMENT_MODELS from '@core/payment/common/constants/paymentModels';
import PAYMENT_MODEL_QUERY from '@core/payment/common/graphql/queries/paymentModel.gql';

import PAYMENT_COINS_MOTIVATION_DATA from '../graphql/queries/paymentCoinsMotivationData.gql';

/**
 * Return data for ExtraCoinsPopup
 * @see openExtraCoinsPopup startExtraCoinsPopupListener
 * @returns {Promise<{available: boolean, discount}>}
 */
const getExtraCoinsPopupData = async () => {
  const client = getClientInstance();

  // TODO: Need to remove checking of payment model after core return correct data for motivation
  const {
    data: {
      payment: {model},
    },
  } = await client.query({query: PAYMENT_MODEL_QUERY});

  const {
    data: {
      payment: {discountData},
    },
  } = await client.query({
    query: PAYMENT_COINS_MOTIVATION_DATA,
    variables: {
      action: PAYMENT_ACTIONS.CREDIT,
      via: ViaEnum.coins_banner_popup,
    },
  });
  const discount = discountData?.percent;
  return {
    available: model === PAYMENT_MODELS.COINS && Boolean(discount),
    discount,
  };
};

export default getExtraCoinsPopupData;
