import React, {useCallback, useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import {ViaEnum} from '@core/types/graphql';
import {createUrlFor} from '@core/utils/url';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import openMessengerWith from '@core/messenger/common/utils/openMessengerWith';
import t from '@core/translations/translate';
import {DEBT_CREDITS_USING} from '@core/messenger/common/constants/reasons';

import {Align} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {ButtonPrimary, SpacingShort} from '@phoenix/ui';
import useTargetUserMainPhotoData from '@phoenix/user/profile/target/utils/useTargetUserMainPhotoData';

import GiftsList from '../components/GiftsList';
import SwitchShowGiftsListButton from '../components/SwitchShowGiftsListButton';
import UserMotivation from '../components/UserMotivation';
import sendGift from '../utils/sendGift';

const HIDED_GIFTS_COUNT = 3;

/**
 * Gift popup
 * @returns {JSX.Element}
 * @constructor
 */
const GiftsPopup = ({userId, gifts, close}) => {
  const isPhone = usePhoneBreakpoint();
  const [disabled, setDisabled] = useState(false);
  const {photo} = useTargetUserMainPhotoData({userId});
  const needHideGifts = gifts.length > HIDED_GIFTS_COUNT;
  const [hideGifts, setHideGifts] = useState(needHideGifts);
  const hidedGiftsList = useMemo(
    () => (needHideGifts ? gifts.slice(0, HIDED_GIFTS_COUNT) : gifts),
    [gifts, needHideGifts],
  );
  const [selectId, setSelectId] = useState(hidedGiftsList[0].id);
  const selectHandler = useCallback((id) => {
    setSelectId(id);
  }, []);
  const switchHideGiftsHandler = useCallback(() => {
    setHideGifts((value) => !value);
    setSelectId(hidedGiftsList[0].id);
  }, [hidedGiftsList]);
  const sentGiftHandler = useCallback(() => {
    setDisabled(true);
    const pathToChat = createUrlFor.chatWith(userId);
    const gotoChat = async () => {
      close();
      await openMessengerWith({
        userId,
        needGTMTrack: true,
      });
    };
    const sendFailHandler = (error) => {
      if (error !== DEBT_CREDITS_USING) {
        gotoChat();
      }
    };
    sendGift({
      returnPath: pathToChat,
      recipientId: userId,
      giftId: selectId,
      placementVia: ViaEnum.popup_gift,
      onSendSuccess: gotoChat,
      onSendFail: sendFailHandler,
    });
  }, [userId, selectId, close]);

  return (
    <PopupNotice
      withoutTop={isPhone}
      heading={<UserMotivation photo={photo} />}
      title={t('gifts', 'title.gift_popup')}
      subTitle={t('gifts', 'text.gift_popup')}
      description={
        <SpacingShort onlyTop>
          <GiftsList
            inline={hideGifts}
            gifts={hideGifts ? hidedGiftsList : gifts}
            onSelect={selectHandler}
            selectId={selectId}
            align={Align.CENTER}
            small
            adaptive={false}
          />
          {needHideGifts && (
            <SwitchShowGiftsListButton
              trackingName={hideGifts ? 'showGiftsList' : 'hideGiftsList'}
              onClick={switchHideGiftsHandler}
            >
              {hideGifts
                ? t('gifts', 'button.choose_another_gift')
                : t('gifts', 'button.hide_gifts')}
            </SwitchShowGiftsListButton>
          )}
        </SpacingShort>
      }
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={sentGiftHandler}
          trackingName="sentGiftPopup"
          disabled={disabled}
        >
          {t('gifts', 'button.send')}
        </ButtonPrimary>,
      ]}
    />
  );
};

GiftsPopup.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string.isRequired,
  gifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
  close: PropTypes.func,
};

export default GiftsPopup;
