import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import {useQuery} from '@apollo/client';

import {ViaEnum} from '@core/types/graphql';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';
import PopupContext from '@core/popup/containers/PopupContext';
import getPriceWithCurrency from '@core/payment/common/utils/getPriceWithCurrency';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import getPayButtonTranslate from '@core/payment/widgets/buttons/utils/getPayButtonTranslate';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import t from '@core/translations/translate';
import remarketingOfferAltMethodProcessorVar from '@core/graphql/vars/remarketingOfferAltMethodProcessorVar';
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';
import getUsersWithCachedUrl from '@core/user/utils/getUsersWithCachedUrl';
import activePackageVar from '@core/graphql/vars/activePackageVar';
import {
  setLoading,
  setReady,
} from '@core/payment/common/utils/setPaymentProcessingStatus';
import logger from '@core/logger';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import trackRmPopup from '@phoenix/tracking/rmPopup/trackRmPopup';
import useTrackPaymentVisit from '@phoenix/payment/pages/utils/useTrackPaymentVisit';
import getButtonTitleForTracking from '@phoenix/tracking/rmPopup/utils/getButtonTitleForTracking';

import RemarketingOfferPaymentDetails from './RemarketingOfferPaymentDetails';
import RemarketingOfferPopupActions from './RemarketingOfferPopupActions';
import RemarketingOfferPopupLayout from '../components/RemarketingOfferPopupLayout';
import {POPUP_SHOW, CHECKBOX_CLICK} from '../constants/trackingName';
import {INVERSE_CLOSE_ICONS} from '../constants/popup';
import getTrackingName from '../utils/getTrackingName';
import REMARKETING_OFFER_PAYMENT_QUERY from '../graphql/queries/remarketingOfferPayment.gql';
import useRemarketingConcept from '../utils/useRemarketingConcept';
import getActivePackage from '../utils/getActivePackage';

const FEATURE_CONCEPT = 'feature_concept';

const mapPropsToParams = ({isPaid}) => ({
  action: isPaid ? PAYMENT_ACTIONS.FEATURES : PAYMENT_ACTIONS.MEMBERSHIP,
  via: ViaEnum.remarketing_show_popup,
  source: PAYMENT_SOURCES.POPUP,
});

const normalizePackage = (activePackage) => {
  const {
    isTrialMembership,
    stockId,
    interval,
    packageTypes,
    packagePricingDetails: {
      percentDiscount,
      packagePriceData: {
        fullPrice: {
          formatted: {amount},
          currency,
        },
      },
      packageDiscountPriceData,
    },
    packageId,
    paymentMethod,
  } = activePackage || {
    interval: 0,
    packageId: '',
    stockId: '',
    packageTypes: [],
    packagePricingDetails: {
      packagePriceData: {
        fullPrice: {
          formatted: {},
          currency: {},
        },
      },
    },
  };

  const discountedAmount =
    packageDiscountPriceData?.fullPrice?.formatted?.amount || 0;

  return {
    stockId,
    type: packageTypes[0],
    interval,
    isTrialMembership,
    discount: percentDiscount?.toString() || '',
    currency: currency.prefix || currency.suffix,
    fullPrice: amount ? getPriceWithCurrency(amount, currency) : null,
    discountedPrice: discountedAmount
      ? getPriceWithCurrency(discountedAmount, currency)
      : null,
    permissionSetId: packageId,
    paymentMethod,
  };
};

const getConceptUsers = memoize((users) => {
  return [
    ...getUsersWithCachedUrl({isPrettyPhoto: true, useViewports: false}),
    ...users,
  ];
});

const RemarketingOfferPopup = ({
  returnPathAfterPay,
  applySecondAttemptTrack,
  goToDefault,
  remarketingOfferData,
  conceptId,
  isPaid,
}) => {
  const {loading: loadingConcept, Concept} = useRemarketingConcept(conceptId);

  const {data, loading, error} = useQuery(REMARKETING_OFFER_PAYMENT_QUERY, {
    variables: {
      action: isPaid ? PAYMENT_ACTIONS.FEATURES : PAYMENT_ACTIONS.MEMBERSHIP,
      via: ViaEnum.remarketing_show_popup,
    },
  });

  const activePackage = useMemo(() => {
    if (loading || error || !data?.payment?.packagesData) {
      return null;
    }

    return getActivePackage(data.payment.packagesData);
  }, [data, loading, error]);

  const normalizedPackage = normalizePackage(activePackage);

  /**
   * conceptId.includes - if the feature concept is called via cookie.
   */
  const forFeatures =
    PackageTypes.FEATURES === normalizedPackage.type ||
    conceptId.includes(FEATURE_CONCEPT);

  const oneClickFlow =
    data?.payment.card?.oneClickFlow || PAYMENT_ONECLICK_FLOWS.SIMPLE;

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      activePackageVar(activePackage);

      setReady();
    }
  }, [loading, activePackage]);

  useEffect(() => {
    const noPackage =
      !loading && !activePackage && !normalizedPackage.permissionSetId;

    if (noPackage || error) {
      if (noPackage) {
        logger.sendError(
          '[RemarketingOfferPopup]: No active package or packageId found',
        );
      }
      goToDefault?.();
    }
  }, [loading, activePackage, normalizedPackage, goToDefault, error]);

  useEffect(() => setPageEnterTime(), []);

  const {setPopupProps} = useContext(PopupContext);
  const isSmallTablet = useSmallTabletBreakpoint();
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const {
    remarketing: {
      paymentPopup: {
        conceptInfo: {users},
        altMethodProcessor,
      },
    },
  } = remarketingOfferData;

  const footerDNSMPI = data?.site?.footer?.footerDNSMPI;
  const pay = data?.payment?.templateSettings?.buttonTitle?.pay;
  const payButtonInfoText =
    data?.payment?.templateSettings?.buttonTitle?.payButtonInfoText;
  const strictLegalMode =
    data?.payment?.paymentFooter?.displaySettingData?.strictLegalMode || false;

  useTrackPaymentVisit();

  const trackingConceptName = useMemo(
    () => getTrackingName(applySecondAttemptTrack, conceptId),
    [applySecondAttemptTrack, conceptId],
  );

  useEffect(() => {
    if (pay) {
      trackRmPopup({
        popupId: trackingConceptName,
        action: POPUP_SHOW,
        buttonTitle: getButtonTitleForTracking(pay),
      });
    }
  }, [trackingConceptName, pay]);

  useEffect(() => {
    remarketingOfferAltMethodProcessorVar(altMethodProcessor);
  }, [altMethodProcessor]);

  const handleCheckboxChange = useCallback(
    (value) => {
      setCheckboxChecked(value);
      trackRmPopup({
        popupId: trackingConceptName,
        action: CHECKBOX_CLICK,
      });
    },
    [trackingConceptName],
  );

  /**
   * Sets props for custom styling of the popup after getting data about popup type
   */
  useEffect(() => {
    const withInverseCloseIcons = INVERSE_CLOSE_ICONS.includes(conceptId);

    if (withInverseCloseIcons) {
      setPopupProps({
        inverseCloseButton: withInverseCloseIcons,
      });
    }
  }, [setPopupProps, conceptId]);

  const price = Number(normalizedPackage.discount)
    ? normalizedPackage.discountedPrice
    : normalizedPackage.fullPrice;

  const paymentButtonTitle = pay
    ? getPayButtonTranslate(pay, {
        '{price}': price,
        '{prefix}': '',
        '{suffix}': '',
      })
    : '';

  const infoText = payButtonInfoText
    ? `${t('paymentPage', 'text.you_will_pay')} ${price}`
    : null;

  const conceptProps = {conceptUsers: getConceptUsers(users)};

  if (forFeatures) {
    conceptProps.discount = normalizedPackage.discount;
    /**
     * If the feature concept is called via cookie.
     */
    normalizedPackage.type = PackageTypes.FEATURES;
  }

  return (
    <PopupFrame
      spacedContent={false}
      adaptive={isSmallTablet}
      actionWithTopMargin
      data-test="remarketingOfferPopup"
      content={
        <Fragment>
          {!loadingConcept && (
            <RemarketingOfferPopupLayout conceptId={conceptId}>
              <Concept {...conceptProps} />
            </RemarketingOfferPopupLayout>
          )}
          <RemarketingOfferPaymentDetails
            hidden={loadingConcept}
            isStrictLegalMode={strictLegalMode}
            forFeatures={forFeatures}
            returnPath={returnPathAfterPay}
            withOneClickInfo={!altMethodProcessor}
            packageId={normalizedPackage.stockId}
            trackingConceptName={trackingConceptName}
            paymentPackage={normalizedPackage}
            onCheckboxChange={handleCheckboxChange}
            paymentButtonTitle={paymentButtonTitle}
          />
        </Fragment>
      }
      actions={
        <RemarketingOfferPopupActions
          loading={loading}
          isStrictLegalMode={strictLegalMode}
          altMethodProcessor={altMethodProcessor}
          paymentPackage={normalizedPackage}
          trackingButtonTitle={getButtonTitleForTracking(pay)}
          paymentButtonTitle={paymentButtonTitle}
          payButtonInfoText={infoText}
          trackingConceptName={trackingConceptName}
          returnPathAfterPay={returnPathAfterPay}
          footerDNSMPI={footerDNSMPI}
          checkboxChecked={checkboxChecked}
          oneClickFlow={oneClickFlow}
        />
      }
    />
  );
};

RemarketingOfferPopup.propTypes /* remove-proptypes */ = {
  /**
   * Since if we go to payment page from here, and return back - we should
   * skip such popup and continue navigation on other funnel steps if it's possible.
   */
  returnPathAfterPay: PropTypes.string,
  applySecondAttemptTrack: PropTypes.bool.isRequired,
  goToDefault: PropTypes.func,
  remarketingOfferData: PropTypes.shape({
    remarketing: PropTypes.shape({
      paymentPopup: PropTypes.shape({
        conceptInfo: PropTypes.shape({
          users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        }),
        altMethodProcessor: PropTypes.string,
      }),
    }),
  }).isRequired,
  conceptId: PropTypes.string.isRequired,
  isPaid: PropTypes.bool.isRequired,
};

export default withStaticPaymentParamsProvider(
  RemarketingOfferPopup,
  mapPropsToParams,
);
