import {makeVar} from '@apollo/client';

import {sessionStorage} from '@core/utils/storage';

import {SHOW_COINS_BUY_OFFER_BANNER_KEY} from '../../constants/storageKeys';

const showCoinsBuyOfferBannerVar = makeVar<boolean>(
  sessionStorage.getItem<boolean>(SHOW_COINS_BUY_OFFER_BANNER_KEY) || false,
);

export default showCoinsBuyOfferBannerVar;
