import React from 'react';

import {ViaEnum} from '@core/types/graphql';
import PopupService from '@core/popup/utils/PopupService';

import ExtraCoinsPopup from '../components/ExtraCoinsPopup';
import getExtraCoinsPopupData from './getExtraCoinsPopupData';
import goToCoinsPP from './goToCoinsPP';

const openExtraCoinsPopup = async () => {
  const {discount} = await getExtraCoinsPopupData();
  const gotoPPHandler = () => goToCoinsPP(ViaEnum.coins_banner_popup);

  PopupService.openPopup(
    <ExtraCoinsPopup
      discount={discount}
      // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
      onClick={gotoPPHandler}
    />,
    {
      small: true,
      trackingName: 'extraCoins',
      autoLoadTracking: true,
    },
  );
};

export default openExtraCoinsPopup;
