import React, {useCallback, useEffect} from 'react';
import {useQuery} from '@apollo/client';

import {ViaEnum} from '@core/types/graphql';
import t from '@core/translations/translate';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import {localStorage} from '@core/utils/storage';
import useActiveSplitGroup from '@core/utils/split/useActiveSplitGroup';
import {SPLITS} from '@core/utils/split';

import {TextMuted} from '@phoenix/typography';
import {ButtonPrimary, Notice} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {trackPaymentVisit} from '@phoenix/payment/pages/utils/useTrackPaymentVisit';
import {TELEGRAM_POPUP_OPENED} from '@phoenix/microfeatures/telegramConversation/constants/storageKey';

import {IconSize} from '../../icons/constants/IconSize';
import {MICROFEATURES, PERIOD} from '../../common/constants/microfeatures';
import usePayForMicroFeature from '../../common/utils/usePayForMicroFeature';
import usePayForMicrofeatureLegacy from '../../common/utils/usePayForMicrofeatureLegacy';
import MicrofeaturesIconTelegramConversation from '../../icons/components/MicrofeaturesIconTelegramConversation';
import MicrofeaturesPaymentPopupFooterLayout from '../../popups/components/MicrofeaturesPaymentPopupFooterLayout';
import PAYMENT_PACKAGE_DATA_QUERY from '../graphql/queries/paymentPackageData.gql';

const usePayForMicroFeatureForPopup = (isFetchApi) => {
  return isFetchApi ? usePayForMicroFeature : usePayForMicrofeatureLegacy;
};

const TelegramConversationMicroFeaturePopup = () => {
  const {action, via, source} = usePaymentParams();
  const {data, loading, error} = useQuery(PAYMENT_PACKAGE_DATA_QUERY, {
    variables: {action, via, source},
  });

  const {result: isFetchApi, loading: isFetchApiLoading} = useActiveSplitGroup(
    SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
    SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
  );

  const microFeaturePackage =
    data?.payment.packagesData.methodTabs[0].packages[0];

  const {processingPayment, pay} = usePayForMicroFeatureForPopup(isFetchApi)({
    featureName: MICROFEATURES.TELEGRAM_CONVERSATION,
    // TODO[BB_removed]: Think about removing stockId from here because is used in 'usePayForMicroFeature' util
    stockId: microFeaturePackage?.stockId,
  });

  const handleClick = useCallback(() => {
    localStorage.removeItem(TELEGRAM_POPUP_OPENED);
    pay();
  }, [pay]);

  useEffect(() => {
    // Need for track pay in popup
    setPageEnterTime();

    trackPaymentVisit(action, via);
  }, [action, via]);

  useEffect(() => {
    localStorage.setItem(TELEGRAM_POPUP_OPENED, true);
  }, []);

  if (loading || isFetchApiLoading) {
    return null;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const fullPrice =
    microFeaturePackage?.packagePricingDetails.packagePriceData.fullPrice;
  const amount = fullPrice?.formatted?.amount;
  const currency = fullPrice?.currency;

  return (
    <PopupFrame
      content={
        <Notice
          spacing={SpacingSize.NONE}
          icon={<MicrofeaturesIconTelegramConversation size={IconSize.LARGE} />}
          title={t('microfeatures', 'header.title.telegram_conversation')}
          description={
            <TextMuted>
              {t('microfeatures', 'desription.telegram_conversation')}
            </TextMuted>
          }
          actions={[
            <ButtonPrimary
              onClick={handleClick}
              fullWidth
              adaptive
              disabledInteraction
              disabled={processingPayment}
              trackingName="activate"
            >
              {t('microfeatures', 'button.activate_now')}
              <PopupLoadTracker />
            </ButtonPrimary>,
          ]}
        />
      }
      footer={
        <MicrofeaturesPaymentPopupFooterLayout
          amount={amount}
          currency={currency}
          period={PERIOD.UNLIMITED}
        />
      }
    />
  );
};

export default withStaticPaymentParamsProvider(
  TelegramConversationMicroFeaturePopup,
  {
    via: ViaEnum.telegramConversation,
    action: PAYMENT_ACTIONS.MICRO_FEATURES,
    source: PAYMENT_SOURCES.POPUP,
  },
);
