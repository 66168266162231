import type {FC} from 'react';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {ViaEnum} from '@core/types/graphql';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import MessageType from '@core/messenger/common/constants/messageType';
import PopupService from '@core/popup/utils/PopupService';
import usePaymentStatus from '@core/payment/common/utils/usePaymentStatus';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import t from '@core/translations/translate';
import useCorrectedPhotoLevel from '@core/user/photo/utils/useCorrectedPhotoLevel';

import openMessengerWith from '@phoenix/messenger/common/utils/openMessengerWith';
import sendMessage from '@phoenix/messenger/senderForm/utils/sendMessage';
import type Photo from '@phoenix/user/photo/types/Photo';

import PhotoRequestPopupLayout from '../components/PhotoRequestPopupLayout';
import usePmaRequestPhoto from '../utils/usePmaRequestPhoto';
import TARGET_USER_PHOTOS_PRIVATE_ATTRIBUTES_QUERY from '../../profile/target/graphql/queries/targetUserPhotosPrivateAttributes.gql';
import type {
  TargetUserPhotosPrivateAttributesQuery,
  TargetUserPhotosPrivateAttributesQueryVariables,
} from '../../profile/target/graphql/queries/targetUserPhotosPrivateAttributes';
import usePhotoUrl from '../utils/usePhotoUrl';

type PmaPhotoRequestPopupProps = {
  userId: string;
  photo: Photo;
};

const PmaPhotoRequestPopup: FC<PmaPhotoRequestPopupProps> = ({
  userId,
  photo,
}) => {
  const {id: photoId, privateAttributes} = photo || {};

  const {isRequested} = privateAttributes || {};

  const [loading, setLoading] = useState(false);

  const {isPaid} = usePaymentStatus();

  const history = useHistory();

  const {allowedRequestsCount, loading: pmaLoading} = usePmaRequestPhoto();

  const canSendRequest = isRequested ? false : Boolean(allowedRequestsCount);

  const {url, loading: photoLoading} = usePhotoUrl(photo);

  const {level, blurLevel} = useCorrectedPhotoLevel({
    ...photo,
  });

  /**
   * Query target user photo when isRequested = false, because need subscribe to flag changing and update layout after send request
   */
  const {data, loading: userPhotoLoading} = useQuery<
    TargetUserPhotosPrivateAttributesQuery,
    TargetUserPhotosPrivateAttributesQueryVariables
  >(TARGET_USER_PHOTOS_PRIVATE_ATTRIBUTES_QUERY, {
    variables: {
      id: userId,
    },
    skip: isRequested,
  });

  const isRequestedPhotoCached = useMemo(() => {
    if (!data || isRequested) {
      return null;
    }

    const {allPhotos, primaryPhoto} = data.user.profile.photos;

    if (primaryPhoto.id === photoId) {
      return primaryPhoto.privateAttributes.isRequested;
    }

    const cachedPhoto = allPhotos.find(({id}) => id === photoId);

    return cachedPhoto?.privateAttributes.isRequested;
  }, [isRequested, data, photoId]);

  const photoIsRequested = isRequested || isRequestedPhotoCached;

  const buttonClickHandle = async () => {
    if (photoIsRequested) {
      // Go to messenger with user
      openMessengerWith({userId, skipMiniMessenger: true});

      PopupService.closePopup();
    } else if (canSendRequest) {
      setLoading(true);

      // Send photo request
      await sendMessage({
        recipientId: userId,
        messageType: MessageType.PRIVATE_PHOTO_REQUEST,
        message: photoId,
      });

      setLoading(false);
    } else if (!isPaid) {
      // Go to payment page
      history.push(
        generatePayUrl({
          stage: PAYMENT_ACTIONS.MEMBERSHIP,
          urlParams: {
            via: ViaEnum.ph_request,
            viaProfileId: userId,
          },
        }),
      );
    }
  };

  const getButtonText = () => {
    if (photoIsRequested) {
      return t('privatePhoto', 'button.go_to_chat');
    }

    if (allowedRequestsCount) {
      return t('privatePhoto', 'button.request_photo_with_count', {
        '{count}': allowedRequestsCount,
      });
    }

    return t('privatePhoto', 'button.request_photo');
  };

  if (pmaLoading || userPhotoLoading || photoLoading) {
    return null;
  }

  return (
    <PhotoRequestPopupLayout
      title={
        photoIsRequested
          ? t('privatePhoto', 'title.request_already_submitted')
          : t('privatePhoto', 'title.request_title')
      }
      description={
        photoIsRequested ? null : t('privatePhoto', 'text.request_details')
      }
      buttonText={getButtonText()}
      // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
      onClick={buttonClickHandle}
      url={url}
      level={level}
      blurLevel={blurLevel}
      loading={loading}
    />
  );
};

export default PmaPhotoRequestPopup;
