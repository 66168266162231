import {getClientInstance} from '@core/graphql/client';
import {readFromLocalStorage, writeToLocalStorage} from '@core/utils/storage';
import getHistory from '@core/application/utils/getHistory';
import PAYMENT_ACCOUNT_STATUS from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import {ViaEnum} from '@core/types/graphql';
import parsePathname from '@core/utils/url/parsePathname';

import REMARKETING_OFFER_AVAILABLE_QUERY from '@phoenix/remarketing/offerPopup/graphql/queries/remarketingOfferAvailable.gql';
import openRemarketingOfferPopup from '@phoenix/remarketing/offerPopup/utils/openRemarketingOfferPopup';

import PMA_REQUEST_PHOTO from '../../user/photo/graphql/queries/pmaRequestPhoto.gql';

export const STORAGE_KEY = 'paymentRedirectPreventCount';
const MAX_PREVENT_REDIRECTS_COUNT = 2;

/**
 * To prevent double popup opening.
 * @type {boolean}
 */
let popupOpened = false;

/**
 * Skip logic for selected vias
 * @type {(string)[]}
 * Export for tests
 */
export const EXCLUDED_VIAS = [
  ViaEnum.remarketing_popup,
  ViaEnum.inpage_decline,
  ViaEnum.paidfunnel_m,
  ViaEnum.repeat_inpage_decline,
];

const hasExcludedVia = (nextLocation) => {
  const {options} = parsePathname(nextLocation);

  return (
    EXCLUDED_VIAS.includes(options.via) ||
    EXCLUDED_VIAS.includes(options.prevVia)
  );
};

/**
 * Set to storage count of prevent payment page redirect
 * @param count
 */
const incrementPaymentRedirectPreventCount = (count) => {
  writeToLocalStorage(STORAGE_KEY, count + 1);
};

/**
 * Prevent redirect to payment page and show RM offer popup
 * return true when need prevent redirect to pp
 * @see paymentNavigationInterceptor.js
 * @returns {boolean}
 */
const showRmPopupInsteadOfPaymentPage = (nextLocation) => {
  if (popupOpened) {
    return true;
  }

  if (hasExcludedVia(nextLocation)) {
    return false;
  }

  const client = getClientInstance();

  // Preloaded by appData.gql
  const pmaRequestData = client.readQuery({query: PMA_REQUEST_PHOTO});

  const {isConfirmedAgeVerification, available} =
    pmaRequestData?.userFeatures.pmaRequestPhoto || {};

  if (!isConfirmedAgeVerification || !available) {
    return false;
  }

  // Preloaded by appData.gql
  const paymentData = client.readQuery({query: PAYMENT_ACCOUNT_STATUS});
  const isPaid = paymentData?.payment.accountStatus.isPaid;

  if (isPaid) {
    return false;
  }

  const redirectCount = readFromLocalStorage(STORAGE_KEY) || 0;
  const needShowPopup = redirectCount < MAX_PREVENT_REDIRECTS_COUNT;

  if (needShowPopup) {
    const returnPathAfterPay = getHistory().location.pathname;

    // Preloaded by appData.gql
    const remarketingOfferData = client.readQuery({
      query: REMARKETING_OFFER_AVAILABLE_QUERY,
    });

    const isPopupAvailable =
      remarketingOfferData?.remarketing?.paymentPopup?.isPopupAvailable;

    if (isPopupAvailable) {
      popupOpened = true;

      openRemarketingOfferPopup({
        checkShown: false,
        returnPathAfterPay,
        onClose: () => {
          popupOpened = false;
        },
      });
      incrementPaymentRedirectPreventCount(redirectCount);
      return true;
    }
  }

  return false;
};

export default showRmPopupInsteadOfPaymentPage;
