import {ViaEnum} from '@core/types/graphql';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import getCoinsSpecialOfferStepsAfterPayment from '@phoenix/payment/payProcess/utils/getCoinsSpecialOfferStepsAfterPayment';
import getCoinsBuyOfferStepsAfterPayment from '@phoenix/payment/payProcess/utils/getCoinsBuyOfferStepsAfterPayment';

const nextCoinsBuyOfferStepAfterPayment: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;

  if (paymentData.prevVia === ViaEnum.popup_unlimWeek) {
    getCoinsSpecialOfferStepsAfterPayment(paymentResult);
  } else {
    getCoinsBuyOfferStepsAfterPayment(data);
  }

  return data;
};

export default nextCoinsBuyOfferStepAfterPayment;
