import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import merge from 'lodash/merge';

import {ViaEnum} from '@core/types/graphql';
import logger from '@core/logger';
import {sessionStorage} from '@core/utils/storage';
import {getClientInstance} from '@core/graphql/client';
import {invalidate} from '@core/graphql/utils/invalidation';
import getHistory from '@core/application/utils/getHistory';
import NaughtyModeLevel from '@core/naughtyMode/constants/NaughtyModeLevel';
import NAUGHTY_MODE_MUTATION from '@core/naughtyMode/graphql/mutations/naughtyMode.gql';
import NAUGHTY_MODE_QUERY from '@core/naughtyMode/graphql/queries/naughtyMode.gql';
import getPaymentStatus from '@core/payment/common/utils/getPaymentStatus';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

import PersonalIdentityService from '@phoenix/personalIdentity/PersonalIdentityService';
import STICKERS_QUERY from '@phoenix/stickersSelector/graphql/queries/stickers.gql';

import NAUGHTY_MODE_STORAGE_KEY from './constants/naughtyModeStorage';

const NaughtyModeService = {
  /**
   * Get naughty mode level from cache
   * @param {object} data - naughty mode data
   * @returns {number}
   */
  getLevel(data = this.data) {
    return parseInt(
      get(
        data,
        'userFeatures.naughtyMode.naughtyMode',
        NaughtyModeLevel.NORMAL,
      ),
      10,
    );
  },

  /**
   * Get naughty mode data from cache
   * @returns {object}
   */
  get data() {
    const data = getClientInstance().readQuery({
      query: NAUGHTY_MODE_QUERY,
    });

    if (!data && !window.IS_INTEGRATION_TEST_ENVIRONMENT) {
      console.error('[NaughtyModeService] There is no naughty mode in cache.');
    }

    return data;
  },

  /**
   * Get free modes from cache
   * @return {array}
   */
  getFreeModes() {
    return get(this.data, 'userFeatures.naughtyMode.freeModes', []);
  },

  /**
   * Get naughty mode level which allows photo level view
   * @param {number} photoLevel
   * @return {number}
   */
  getNaughtyModeByPhotoLevel(photoLevel) {
    const availableMode = find(
      get(this.data, 'userFeatures.naughtyMode.availableModes', []),
      (photoLevels) => {
        return includes(photoLevels.contentLevels, Number(photoLevel));
      },
    );

    if (availableMode) {
      return Number(availableMode.id);
    }

    return NaughtyModeLevel.NORMAL;
  },

  /**
   * Check naughty mode is present on photo/video widget
   * @param {number} photoLevel Photo level
   * @return {boolean}
   */
  isNaughtyModeHolderOnMedia(photoLevel) {
    const {availableModes = [], naughtyMode} =
      this.data?.userFeatures?.naughtyMode || {};
    const currentMode = find(availableModes, {id: naughtyMode});
    if (!currentMode) {
      return false;
    }

    return !currentMode.contentLevels.includes(Number(photoLevel));
  },

  /**
   * @param {number|null} level
   * @param {number} compareWith
   * @returns {boolean}
   */
  compareLevel(level = null, compareWith) {
    return (level || this.getLevel()) === compareWith;
  },

  /**
   * @param {number|null} level
   * @return {boolean}
   */
  isNoLimitsLevel(level = null) {
    return this.compareLevel(level, NaughtyModeLevel.NO_LIMITS);
  },

  /**
   * @param {number|null} level
   * @return {boolean}
   */
  isNormalLevel(level = null) {
    return this.compareLevel(level, NaughtyModeLevel.NORMAL);
  },

  /**
   * @param {number|null} level
   * @return {boolean}
   */
  isSexyLevel(level = null) {
    return this.compareLevel(level, NaughtyModeLevel.SEXY);
  },

  /**
   * @return {boolean}
   */
  isStrictNudityLaws() {
    return get(this.data, 'userFeatures.isStrictNudityLaws', false);
  },

  /**
   * @param {number} level
   * @return {Promise<Boolean>}
   */
  async isPaidNaughtyMode(level = this.getLevel()) {
    const isPaid = await getPaymentStatus();

    if (
      level > NaughtyModeLevel.NORMAL &&
      PersonalIdentityService.isVerificationRequired()
    ) {
      return !isPaid;
    }

    return !this.getFreeModes().includes(level);
  },

  /**
   * @param {Number} naughtyMode
   * @returns {ViaEnum}
   */
  getPaidNaughtyModeVia(naughtyMode) {
    return naughtyMode === NaughtyModeLevel.NO_LIMITS
      ? ViaEnum.paid_nm_no_limits
      : ViaEnum.paid_nm_sexy;
  },

  /**
   * @param {Object} client
   * @param {Number} naughtyMode
   * @returns {void}
   */
  updateNaughtyModeCache({client = getClientInstance(), naughtyMode}) {
    try {
      client.writeQuery({
        query: NAUGHTY_MODE_QUERY,
        data: merge({}, this.data, {
          userFeatures: {
            naughtyMode: {
              naughtyMode,
            },
          },
        }),
      });
    } catch (e) {
      logger.sendWarning(
        `[NaughtyModeService:updateNaughtyModeCache] Error ${e.message}`,
      );
    }

    /**
     * Invalidate reels videos when naughty mod is changing
     * @see ReelsPage.js
     */
    invalidateCacheByTypename(client, 'UserFeatures', 'reels');

    // We have to invalidate stickers cache when a naughty mode is changing
    invalidate(STICKERS_QUERY);
  },

  /**
   * @param {Number} naughtyMode
   * @returns {Promise<void>}
   */
  async updateNaughtyMode({naughtyMode}) {
    const history = getHistory();
    const client = getClientInstance();
    const currentNaughtyMode = this.getLevel(this.data);

    if (await this.isPaidNaughtyMode(naughtyMode)) {
      const payUrl = generatePayUrl({
        stage: PAYMENT_ACTIONS.MEMBERSHIP,
        urlParams: {
          via: this.getPaidNaughtyModeVia(naughtyMode),
        },
      });
      sessionStorage.setItem(NAUGHTY_MODE_STORAGE_KEY, naughtyMode);

      history.push(payUrl);
    } else if (
      PersonalIdentityService.isVerificationRequired() &&
      !PersonalIdentityService.firstDayLogic &&
      currentNaughtyMode === NaughtyModeLevel.NORMAL &&
      naughtyMode > NaughtyModeLevel.NORMAL
    ) {
      history.push('/personalIdentity');
    } else if (naughtyMode !== currentNaughtyMode) {
      await client.mutate({
        mutation: NAUGHTY_MODE_MUTATION,
        variables: {naughtyMode},
        update(
          cache,
          {
            data: {
              userFeatures: {
                naughtyMode: {errors},
              },
            },
          },
        ) {
          if (!errors.general) {
            NaughtyModeService.updateNaughtyModeCache({client, naughtyMode});
          }
        },
      });
    }
  },
};

export default NaughtyModeService;
