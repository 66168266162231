import {pipe} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import delay from 'lodash/delay';

import {ViaEnum} from '@core/types/graphql';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';
import isPayUrl from '@core/utils/url/isPayUrl';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import ROUTES from '@core/application/constants/routes';
import isMobileViewportCached from '@core/responsive/isMobileViewportCached';
import {localStorage} from '@core/utils/storage';

import PAYMENT_PACKAGE_DATA_QUERY from '@phoenix/microfeatures/telegramConversation/graphql/queries/paymentPackageData.gql';
import REMARKETING_OFFER_AVAILABLE_QUERY from '@phoenix/remarketing/offerPopup/graphql/queries/remarketingOfferAvailable.gql';
import openTelegramConversationMicroFeaturePopup from '@phoenix/microfeatures/telegramConversation/utils/openTelegramConversationMicroFeaturePopup';
import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';
import {TELEGRAM_POPUP_OPENED} from '@phoenix/microfeatures/telegramConversation/constants/storageKey';

import IS_PAID_QUERY from '../graphql/queries/isPaid.gql';

const OPENING_DELAY = 500;
const OPENING_DELAY_WHEN_RM_POPUP_IS_ENABLED = 2000;

/**
 * Display microfeature telegram conversation popup
 */
const telegramConversationPopupAction = (client) => ({
  operations: () =>
    pipe(
      filter(([{data}]) =>
        data.route.current.startsWith(
          `/search/index/featurePromo/${MICROFEATURES.TELEGRAM_CONVERSATION}`,
        ),
      ),
      take(1),
    ),
  subscription: async ([route]) => {
    const history = getHistory();

    // When user is back from payment page need redirect to search
    if (
      isPayUrl(route.data.route.previous) &&
      localStorage.getItem(TELEGRAM_POPUP_OPENED)
    ) {
      localStorage.removeItem(TELEGRAM_POPUP_OPENED);
      history.replace(SEARCH_PAGE_LINK);
      return;
    }

    const {data} = await client.query({query: IS_PAID_QUERY});

    const {isPaid} = data.payment.accountStatus;

    // Redirect not paid user to payment page
    if (!isPaid) {
      const paymentUrl = generatePayUrl({
        stage: PAYMENT_ACTIONS.MEMBERSHIP,
        urlParams: {
          via: ViaEnum.telegramConversation,
          returnPath: ROUTES.SEARCH,
          skipStepOnBack: true,
        },
      });

      history.push(paymentUrl);

      return;
    }

    const isPhone = isMobileViewportCached();

    // Tg microfeature popup not allowed for web
    if (!isPhone) {
      return;
    }

    const {data: packagedData} = await client.query({
      query: PAYMENT_PACKAGE_DATA_QUERY,
      variables: {
        via: ViaEnum.telegramConversation,
        action: PAYMENT_ACTIONS.MICRO_FEATURES,
      },
    });

    const microFeaturePackage =
      packagedData.payment.packagesData.methodTabs[0].packages[0];

    if (!microFeaturePackage) {
      return;
    }

    const {data: rmPopupData} = await client.query({
      query: REMARKETING_OFFER_AVAILABLE_QUERY,
    });

    const showDelayTime = rmPopupData.remarketing.paymentPopup.isPopupAvailable
      ? OPENING_DELAY_WHEN_RM_POPUP_IS_ENABLED
      : OPENING_DELAY;

    /**
     * Popup show priority fix
     * Cannot use all checks for RM popup is enabling and show logic in this place.
     * Therefore, if the rm popup is available, we increase the delay before TG popup is displaying .
     */
    delay(() => {
      openTelegramConversationMicroFeaturePopup();
    }, showDelayTime);
  },
});

export default telegramConversationPopupAction;
