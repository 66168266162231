import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {ViaEnum} from '@core/types/graphql';
import isInRouteList from '@core/utils/routing/isInRouteList';

const PROFILE_ROUTES = ['/profile', '/profile/menu'];

/**
 * Return via for refill balance
 * Getting packages and logic PP for coins depends of via.
 * @see vias.js
 * @returns {string}
 */
const useViaForBalanceRefill = () => {
  const location = useLocation();

  return useMemo(() => {
    return isInRouteList(PROFILE_ROUTES, location.pathname)
      ? ViaEnum.balance_profile
      : ViaEnum.balance_header;
  }, [location]);
};

export default useViaForBalanceRefill;
