import MessageType from '@core/messenger/common/constants/messageType';
import {ViaEnum} from '@core/types/graphql';

/**
 * Via for unsent message buy type of message
 * @param {string} type
 * @returns {string|string}
 */
const getViaForCoinsByType = (type) => {
  switch (type) {
    case MessageType.IMB_IMAGE:
    case MessageType.IMB_VIDEO:
      return ViaEnum.coin_mms;

    // For default use via for a chat type message
    default:
      return ViaEnum.coin_sms;
  }
};

export default getViaForCoinsByType;
