import {useState, useContext, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router';

import {ViaEnum} from '@core/types/graphql';
import PopupService from '@core/popup/utils/PopupService';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';
import {CARD_FORM_NAME} from '@core/payment/payProcess/constants/formNames';
import PAY_BUTTON_TRACKING from '@core/payment/widgets/buttons/constants/payButtonTracking';
import usePaymentPackages from '@core/payment/widgets/package/utils/usePaymentPackages';
import generatePayUrlForCoins from '@core/payment/common/utils/generatePayUrlForCoins';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import {setReady} from '@core/payment/common/utils/setPaymentProcessingStatus';

import updateMicroFeaturesData from '@phoenix/payment/payProcess/utils/updateMicroFeaturesData';
import CREDITS_BALANCE_QUERY from '@phoenix/credits/graphql/queries/creditsBalance.gql';
import getMicroFeaturesPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getMicroFeaturesPopupFlow';
import getMicroFeaturesCoinsPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getMicroFeaturesCoinsPopupFlow';
import getExchangeCoinsPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getExchangeCoinsPopupFlow';
import useOneClickFlow from '@phoenix/payment/widgets/oneClickFlow/utils/useOneClickFlow';

import openMicrofeaturesSuccessPopup from '../../popups/utils/openMicrofeaturesSuccessPopup';
import MicrofeaturesContext from '../containers/MicrofeaturesContext';
import {MODEL_TYPES} from '../constants/modelTypes';

/**
 * @param {Object} params
 * @param {string} params.featureName (@see MICROFEATURES from common/constants/microfeatures.js)
 * @return {{
 * pay: {function} oneClick payment or redirect to PP,
 * processingPayment: {boolean} payment state,
 * packagesError: {ApolloError|Undefined}
 * packagesLoading: {boolean}
 * }}
 */
const usePayForMicroFeature = ({featureName, stockId: featureStockId} = {}) => {
  // @see MicrofeaturesProvider.js
  const {modelType, getFeatureData} = useContext(MicrofeaturesContext);
  const {pathname} = useLocation();
  const creditModel = modelType === MODEL_TYPES.CREDIT;
  const {data: balanceData} = useQuery(CREDITS_BALANCE_QUERY, {
    skip: !creditModel,
  });

  const {action, via, source} = usePaymentParams();

  const {activePackage, error, loading} = usePaymentPackages({
    needResetCache: true,
  });

  const {oneClickFlow} = useOneClickFlow();

  const featureData = getFeatureData({featureName});
  const stockId = featureStockId || featureData.stockId;

  const history = useHistory();
  const [processingPayment, setProcessingPayment] = useState(false);

  const trackClick = useCallback(
    ({isButtonActive = true} = {}) => {
      trackPaymentClick({
        action,
        isFormHidden: true,
        isFrontendValidationPassed: true,
        isOneClickShown: false,
        isButtonActive,
        payButton: PAY_BUTTON_TRACKING.DEFAULT,
        paymentForm: CARD_FORM_NAME,
        timeInterval: getPageViewTime(),
      });
    },
    [action],
  );

  const handleSuccessfulPayment = useCallback(async () => {
    setProcessingPayment(false);
    setReady();

    PopupService.closePopup();
    openMicrofeaturesSuccessPopup({featureName});
    /**
     * No need to use updateCachesAfterPayment after microfeature payment,
     * because it currently doesn't change none of that data (search users, account status, viewPermissions and so on)
     */
    await updateMicroFeaturesData();
  }, [featureName]);

  const handleCreditPayment = useCallback(
    async (result) => {
      if (result.status) {
        await handleSuccessfulPayment();
      } else {
        // When we got Decline we redirect to PP and reset Cached package
        const purchasedPackage = cachedPurchasedPackage.get();

        history.push(
          generatePayUrl({
            stage: action,
            urlParams: {
              via: result.failPageLogic,
              prevVia: result.via,
              stockId: purchasedPackage?.stockId,
            },
          }),
        );
      }
    },
    [handleSuccessfulPayment, action, history],
  );

  const buyMicrofeature = useCallback(async () => {
    if (processingPayment) {
      trackClick({isButtonActive: false});
      return;
    }

    setProcessingPayment(true);
    // TODO[BB_removed]: Think about move to payment flow
    trackClick();

    const returnPathParams = {
      returnPath: pathname,
    };
    const payUrlParams = {
      prevVia: featureName,
      viaMethod: PAYMENT_METHODS.CARD,
      packageId: stockId,
      ...returnPathParams,
    };

    const isOneClickAllowed = await isCardOneClickAllowed({
      source,
      fetchPolicy: 'network-only',
      via: featureName,
    });

    if (isOneClickAllowed && oneClickFlow !== PAYMENT_ONECLICK_FLOWS.SIMPLE) {
      if (modelType === MODEL_TYPES.CREDIT) {
        history.push(generatePayUrlForCoins({via: featureName}));
      } else {
        history.push(
          generatePayUrl({
            stage: action,
            urlParams: {
              via: ViaEnum.microFeatures,
              ...payUrlParams,
            },
          }),
        );
      }
      return;
    }

    if (modelType === MODEL_TYPES.CREDIT) {
      const {balance} = balanceData.credits;
      const canExchange = balance > featureData.creditAmount;

      if (!isOneClickAllowed && !canExchange) {
        history.push(generatePayUrlForCoins({via: featureName}));
      }

      if (canExchange) {
        const exchangeResult = await getProcessPaymentFlow(
          getExchangeCoinsPopupFlow(),
        )({
          action: PAYMENT_ACTIONS.EXCHANGE_CREDIT,
          activePackage,
          hidePaymentForm: 1,
          isInstantRepeat: 0,
          // --> TODO[BB_removed]: Try to deal with BU PM to setup correct packages to use 'activePackage' instead
          method: PAYMENT_METHODS.CARD,
          prevVia: via,
          stockId: featureData.packageId,
          via: PAYMENT_ACTIONS.EXCHANGE_CREDIT,
          // <-- TODO[BB_removed]
        });

        // TODO[BB_removed]: Think about move to separate methods in 'getExchangeCoinsPopupFlow' util
        await handleCreditPayment(exchangeResult);

        return;
      }

      const paymentAnswer = await getProcessPaymentFlow(
        getMicroFeaturesCoinsPopupFlow(),
      )({
        action,
        activePackage,
        form: {
          hidePaymentForm: 1,
        },
        via,
      });

      // TODO[BB_removed]: Think about move to separate methods in 'getMicroFeaturesCoinsPopupFlow' util
      await handleCreditPayment(paymentAnswer);

      return;
    }

    if (isOneClickAllowed) {
      const result = await getProcessPaymentFlow(getMicroFeaturesPopupFlow())({
        action,
        hidePaymentForm: 1,
        isInstantRepeat: 0,
        // --> TODO[BB_removed]: Try to deal with BU PM to setup correct packages to use 'activePackage' instead
        method: PAYMENT_METHODS.CARD,
        prevVia: via,
        stockId,
        via: action,
        // <-- TODO[BB_removed]
      });

      // TODO[BB_removed]: Think about move to separate method in 'getMicroFeaturesPopupFlow' util
      if (result.status) {
        await handleSuccessfulPayment();
      }

      return;
    }

    // go to PP if oneClick is not allowed
    history.push(
      generatePayUrl({
        stage: action,
        urlParams: {
          via: ViaEnum.microFeatures,
          ...payUrlParams,
        },
      }),
    );
  }, [
    action,
    via,
    activePackage,
    balanceData,
    featureData.creditAmount,
    featureData.packageId,
    featureName,
    handleCreditPayment,
    handleSuccessfulPayment,
    history,
    modelType,
    pathname,
    processingPayment,
    stockId,
    trackClick,
    oneClickFlow,
    source,
  ]);

  return {
    packagesError: error,
    packagesLoading: loading,
    processingPayment,
    pay: buyMicrofeature,
  };
};

export default usePayForMicroFeature;
