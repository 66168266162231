import {ViaEnum} from '@core/types/graphql';

const RESTRICTED_VIA_LIST = [
  ViaEnum.buy3DayGet1WeekPopup,
  ViaEnum.extraDayPopup,
  ViaEnum.spin_extraDay,
  ViaEnum.mail_ics,
  ViaEnum.remove_account,
];

export default RESTRICTED_VIA_LIST;
