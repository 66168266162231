import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {H4, TextMuted} from '@phoenix/typography';

import css from '../styles/PaymentPackageTitle.css';

const PaymentPackageTitle = ({
  title,
  subTitle,
  inverse,
  capitalize = true,
  lineThrough,
  withBigTextSize,
  uppercase = false,
}) => (
  <div className={css.container}>
    <H4
      inverse={inverse}
      className={cn(
        css.title,
        capitalize && css.capitalize,
        uppercase && css.uppercase,
      )}
    >
      <span className={lineThrough ? css.titleLineThrough : ''}>{title}</span>
    </H4>
    {Boolean(subTitle) && (
      <TextMuted inverse={inverse} small={!withBigTextSize} ellipsis>
        {subTitle}
      </TextMuted>
    )}
  </div>
);

PaymentPackageTitle.propTypes /* remove-proptypes */ = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
  inverse: PropTypes.bool,
  lineThrough: PropTypes.bool,
  withBigTextSize: PropTypes.bool,
};

export default PaymentPackageTitle;
