import type {FC} from 'react';
import React, {useCallback} from 'react';
import {useMutation, useQuery} from '@apollo/client';

import {ViaEnum} from '@core/types/graphql';
import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';
import logger from '@core/logger';
import TARGET_USER_PHOTOS_FRAGMENT from '@core/user/profile/target/graphql/fragments/targetUserPhotos.gql';
import {createUrlFor} from '@core/utils/url';
import SHOW_ALL_PRIVATE_PHOTOS_MUTATION from '@core/coins/graphql/mutations/showAllPrivatePhotos.gql';
import type {
  ShowAllPrivatePhotosMutation,
  ShowAllPrivatePhotosMutationVariables,
} from '@core/coins/graphql/mutations/showAllPrivatePhotos';
import type {TargetUserPhotosFragment} from '@core/user/profile/target/graphql/fragments/targetUserPhotos';

import PrivatePhotoOverlayType from '@phoenix/user/photo/constants/PrivatePhotoOverlayType';
import SHOW_ALL_PRIVATE_PHOTOS_COST_QUERY from '@phoenix/coins/graphql/queries/showAllPrivatePhotosCost.gql';
import type {ShowAllPrivatePhotosCostQuery} from '@phoenix/coins/graphql/queries/showAllPrivatePhotosCost';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {NO_ENOUGH_COINS} from '@phoenix/credits/constants/declineReason';
import goToCoinsPP from '@phoenix/coins/utils/goToCoinsPP';

import type PhotoRequestData from '../types/PhotoRequestData';
import PrivateAlbumPhotoOverlayLayout from '../components/PrivateAlbumPhotoOverlayLayout';

type PrivateAlbumPhotoOverlayProps = {
  photoRequestData: PhotoRequestData;
};

const PrivateAlbumPhotoOverlay: FC<PrivateAlbumPhotoOverlayProps> = ({
  photoRequestData,
}) => {
  const {userId, overlayType} = photoRequestData;

  const withRequest =
    overlayType === PrivatePhotoOverlayType.WITH_REQUEST_BUTTON;

  const {data, loading, error} = useQuery<ShowAllPrivatePhotosCostQuery>(
    SHOW_ALL_PRIVATE_PHOTOS_COST_QUERY,
    {
      skip: !withRequest,
    },
  );

  const [showAllPrivatePhotos, {loading: mutating}] = useMutation<
    ShowAllPrivatePhotosMutation,
    ShowAllPrivatePhotosMutationVariables
  >(SHOW_ALL_PRIVATE_PHOTOS_MUTATION, {
    variables: {
      userId,
    },
  });

  const handleCLick = useCallback(() => {
    if (mutating) {
      return;
    }

    showAllPrivatePhotos({
      update: (cache, {data: resultData}) => {
        const {result, errors} =
          resultData?.credits?.showAllPrivatePhotos || {};

        if (result) {
          const fragmentOptions = {
            fragment: TARGET_USER_PHOTOS_FRAGMENT,
            fragmentName: 'TargetUserPhotos',
            id: `Profile:${userId}`,
          };
          const fragmentData =
            cache.readFragment<TargetUserPhotosFragment>(fragmentOptions);

          if (fragmentData) {
            cache.writeFragment<TargetUserPhotosFragment>({
              ...fragmentOptions,
              data: {
                photos: {
                  ...fragmentData.photos,
                  allPhotos: fragmentData.photos.allPhotos.map((photo) => {
                    if (photo?.privateAttributes?.isPrivate) {
                      return {
                        ...photo,
                        privateAttributes: {
                          ...photo.privateAttributes,
                          isRequested: true,
                        },
                      };
                    }

                    return photo;
                  }),
                },
              },
            });
          }

          return;
        }

        const normalizedErrors = errors && normalizeMutationErrors(errors);

        if (normalizedErrors && normalizedErrors.general === NO_ENOUGH_COINS) {
          goToCoinsPP(ViaEnum.privateAlbum, createUrlFor.userProfile(userId));
        } else if (normalizedErrors) {
          logger.sendError(
            '[PrivateAlbumPhotoOverlay] showAllPrivatePhotos mutation error',
            normalizedErrors,
          );
        }
      },
    });
  }, [userId, mutating, showAllPrivatePhotos]);

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (loading) {
    return null;
  }

  if (withRequest || overlayType === PrivatePhotoOverlayType.DESCRIPTION) {
    return (
      <PrivateAlbumPhotoOverlayLayout
        loading={mutating}
        // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
        onClick={withRequest ? handleCLick : null}
        price={data?.credits.paidFeatures.showAllPrivatePhotosCost}
      />
    );
  }

  return null;
};

export default PrivateAlbumPhotoOverlay;
