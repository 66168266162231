import React from 'react';

import {ViaEnum} from '@core/types/graphql';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {PHOTO_SETTINGS} from '@core/mediaUpload/constants/mediaSettings';
import {PERSONAL_IDENTITY} from '@core/mediaUpload/constants/sources';
import PopupService from '@core/popup/utils/PopupService';
import {IMAGES} from '@core/mediaUpload/constants/accept';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

import PhotoUploadPopup from '@phoenix/mediaUpload/containers/PhotoUploadPopup';
import {PENDING_TO_APPROVE} from '@phoenix/personalIdentity/constants/documentStatus';
import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import AgeVerificationUploadInfo from '@phoenix/personalIdentity/components/AgeVerificationUploadInfo';
import {PAID_FUNNEL_PAGE} from '@phoenix/inAppMotivation/constants/motivationTypes';

import updatePersonalIdentityDocumentStatus from './updatePersonalIdentityDocumentStatus';

const openPersonalIdentityByDemandDocumentUploadPopup = (country) => {
  const isSnapchat =
    getBootstrapParam(IN_APP_NAME) === InAppBrowserName.SNAPCHAT;

  if (isSnapchat) {
    openInAppMotivationPopup({
      motivationType: PAID_FUNNEL_PAGE,
      withPhoto: true,
    });

    return;
  }

  PopupService.openPopup(
    <PhotoUploadPopup
      settings={{
        ...PHOTO_SETTINGS,
        source: PERSONAL_IDENTITY,
        attachToUser: false,
        via: ViaEnum.verification,
        country,
        accept: IMAGES,
        multiple: false,
        onSuccess: () => {
          updatePersonalIdentityDocumentStatus(PENDING_TO_APPROVE);
        },
      }}
      footer={<AgeVerificationUploadInfo />}
      spacedAbove
      unspacedBelow={false}
    />,
    {small: true, trackingName: 'personalIdentityByDemandDocumentUpload'},
  );
};

export default openPersonalIdentityByDemandDocumentUploadPopup;
